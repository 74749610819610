import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { Result, Button } from 'antd';

function ErrorBoundary() {
  const error = useRouteError();
  const errorText = String(error);

  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>Oops!</h1>
        <h2>{error.status}</h2>
        <p>{error.statusText}</p>
        {error.data?.message && <p>{error.data.message}</p>}
      </div>
    );
  }

  const onRefresh = () => {
    window.location.reload();
  };

  if (
    errorText &&
    errorText?.includes('Loading') &&
    errorText?.includes('chunk') &&
    errorText?.includes('failed')
  ) {
    if (location.href.indexOf('#reloaded') === -1) {
      window.location.href += '#reloaded';
      setTimeout(() => {
        onRefresh();
      }, 1000);
    } else {
      <Result
        status="warning"
        title="Sorry,There is an error on this page,Please click the refresh button to reload the page."
        subTitle=""
        extra={[
          <Button type="primary" key="refresh" onClick={onRefresh}>
            Refresh
          </Button>,
        ]}
      />;
    }
    return null;
  }
  return (
    <Result status="error" title="Sorry,There is an error on this page" subTitle={errorText} />
  );
}
export default ErrorBoundary;
