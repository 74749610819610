import type { AxiosInstance, AxiosResponse } from 'axios';
import { message } from 'antd';
import axios from 'axios';
// import dayjs from 'dayjs';
import { store } from '@/store';
import { currentEnv, proxyUrl } from '@/constant/url';
import { cookie } from '@/utils/cookie';
import { COOKIE_TOKEN_NAME, COOKIE_REFRESH_TOKEN_NAME } from '@/constant';
import { getRefreshToken } from '@/apis/app';

const NO_AUTH_STATUS_CODE = [403];
console.log(currentEnv, 'currentEnv');
// console.log(document.referrer, 'document.referrer');

class Request {
  private request(opts: {}) {
    const instance: AxiosInstance = axios.create({
      baseURL: currentEnv === 'dev' ? proxyUrl : '',
      timeout: 80000,
      headers: { 'content-type': 'application/json' },
      withCredentials: true,
    });
    this.setInterceptors(instance);
    return instance(opts);
  }

  // 退出登录
  logOut() {
    store.dispatch.app.setLoginState('');
    message.warning('The session is invalid. Please login again');
    window.location.href = '/login';
    cookie.delete(COOKIE_TOKEN_NAME, '/');
    cookie.delete(COOKIE_REFRESH_TOKEN_NAME, '/');
  }

  // 刷新token
  async refreshToken(newToken, res) {
    const response = await getRefreshToken({ token: newToken });
    const data = response.data;
    const refreshToken = data?.refreshToken?.token;
    if (data?.accessToken) {
      const accessToken = data.accessToken.token;
      cookie.set(COOKIE_TOKEN_NAME, accessToken, null, '/');
      cookie.set(COOKIE_REFRESH_TOKEN_NAME, refreshToken, null, '/');
      store.dispatch.app.setUserInfo(data);
      if (res.config.method === 'post') res.config.data = JSON.parse(res.config.data);
      return this.request(res.config);
    }
  }

  // 拦截器
  private setInterceptors(instance: AxiosInstance) {
    const accessToken = cookie.get(COOKIE_TOKEN_NAME);
    const currentRegulator =
      sessionStorage.getItem('currentRegulator') ||
      localStorage.getItem('currentRegulator') ||
      store.getState().app.currentRegulator;

    // 请求拦截器
    instance.interceptors.request.use((config: any) => {
      // console.log(sessionStorage.getItem('currentRegulator'),store.getState().app.currentRegulator,'===监管信息');
      config.headers = {
        ...config.headers,
        Regulator: currentRegulator,
        Language: store.getState().app.lang,
        timezone: new Date().getTimezoneOffset() / -60, // 时区偏移
        Authorization: accessToken && accessToken !== 'null' ? `Bearer ${accessToken}` : '',
      };
      return config;
    });
    // 响应拦截器
    instance.interceptors.response.use(
      (res: AxiosResponse) => {
        if (res.status === 200) {
          const newToken = cookie.get(COOKIE_REFRESH_TOKEN_NAME);
          if (res.data.code === '1001') {
            if (newToken && newToken !== 'null') return this.refreshToken(newToken, res);
            this.logOut();
          }
          return Promise.resolve(res.data);
        }
        return Promise.reject(res.data);
      },
      (err) => {
        console.log(err?.response, 'err');
        const resStatus = err.response?.status || 0;
        if (resStatus === 401) {
          this.logOut();
        } else if (NO_AUTH_STATUS_CODE.includes(resStatus)) {
          // 没有权限
          window.location.href = '/403';
        } else if (err?.response?.data?.message) {
          message.error(err?.response?.data?.message);
        } else if (resStatus) {
          message.error(
            `Brower status: ${err?.response?.status},The Internet Error,Please Try Again!`
          );
        }
        return Promise.reject(err);
      }
    );
  }

  // 封装get请求
  public get(url: string, params?: any) {
    return this.request({
      url,
      method: 'get',
      // headers: {
      //   'Content-Type': 'application/x-www-form-urlencoded',
      // },
      params,
    });
  }

  // 封装post请求
  public post(url: string, data?: any) {
    return this.request({
      url,
      method: 'post',
      data,
    });
  }

  // 封装get请求
  public rcGet(url: string, token: string, params?: any) {
    return this.request({
      url,
      method: 'get',
      headers: {
        'RC-Authorization': `Bearer ${token}`,
      },
      params,
    });
  }

  public rcPost(url: string, token: string, data?: any) {
    return this.request({
      url,
      method: 'post',
      headers: {
        'RC-Authorization': `Bearer ${token}`,
      },
      data,
    });
  }
}
export default Request;
